import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterMetadatosForm = new APIFilter()
    apiFilterMetadatosForm.addLT('estado', 0)
    apiCalls.push({ name: 'selectMetadatosForm', method: 'cita.select', params: { filter: apiFilterMetadatosForm } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idcita', id)
    const resp = await Vue.$api.call('cita.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
}
