<template>
  <b10-base>
    <b10-text-field
      v-model="form.titulo"
      clearable
      flat
      label="Título"
      :validation-rules="formRules.titulo"
    />
    <b10-date-time-picker
      v-model="form.fcita"
      title="Fecha de inicio"
      :validation-rules="formRules.fcita"
      :with-validation-provider="true"
    />
    <b10-date-time-picker
      v-model="form.ffin"
      title="Fecha de fin"
      :validation-rules="formRules.ffin"
      :with-validation-provider="true"
    />
    <b10-textarea
      v-model="form.cita"
      label="Observaciones"
      :validation-rules="formRules.cita"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './CitaFormData'
import  { extend } from 'vee-validate'

export default {
  mixins: [formMixin],
  props: {
    fcita: {
      type: Date,
      default: function () { return new Date() },
    },
  },
  data () {
    return {
      form: {
        fcita: this.fcita,
        ffin: null,
        titulo: null,
        cita: null,
      },
      formRules: {
        fcita: { required: true },
        titulo: { required: true },
        ffin: { fechaFinValida: true },
      },
    }
  },
  async created () {
    extend('fechaFinValida', {
      message: 'La fecha de fin debe ser posterior a la de inicio.',
      validate: async (value) => {
        if (value && value < this.form.fcita) {
          return false
        } else {
          return true
        }
      }
    })
    const resp = await Data.selectLookups(this)
    await this.addFormRulesVeeValidateAPI(resp.data.selectMetadatosForm.result.metadata.columns)
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
  },
}
</script>
